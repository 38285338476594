.item-list {
    margin-top: 1rem;
}

.item-list .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
}

h3 {
    font-size: 1.55rem;
}

.video-name {
    margin: 0;
    padding: 0 5px;
    font-size: 0.85em;
    color: #000;
    cursor: pointer;
    transition: .3s;
}

.video-name:hover {
    color: #555;
}

.delete-btn {
    background: transparent;
    border: none;
    outline: none;
}