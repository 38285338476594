@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px)
    } 
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.search {
    position: relative;
    margin: 30px 0;
}

.search-result {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 220px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    list-style: none;
    background: lightgrey;
    border-radius: .25rem;
    z-index: 99;
    animation: fadeIn ease-out .5s;
}

.search-field {
    position: relative;
    display: flex;
}

.search-field .search-input {
    padding-right: 30px;
}

.search-field .search-btn {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 18px;
    background: transparent;
    border: none;
    outline: none;
    transform: translateY(-50%)
}

.hidden {
    display: none;
}

.list-item  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.left-side {
    display: flex;
    align-items: center;
}

.left-side .description {
    padding: 0 15px;
}

.left-side .description p {
    font-size: 0.9rem;
}

.btn-play {
    font-size: 28px;
    background: transparent;
    border: none;
    outline: none;
    transition: .3s ease;
}

.btn-play:hover {
    color: rgba(184, 43, 43, 0.644);
}

.close-btn {
    position: absolute; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    top: calc(100% + 220px - 27px);
    height: 27px;
    font-size: 22px;
    background: rgba(0, 0, 0, .8);
    text-align: center;
    color: #fff;
    z-index: 99;
    animation: fadeIn ease-out .5s;
}

.close-btn i {
    cursor: pointer;
}