.dropbtn {
    padding: 5px 0;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: absolute;
    display: inline-block;
    top: 30px;
    right: 0;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 80px;
}

.dropbtn{
  width: 75px;
}

.dropbtn::after {
    background: rgba(0, 0, 0, 0) url("https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-16.png") no-repeat scroll center center;
    content: "";
    height: 16px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 16px;
}

/* Links inside the dropdown */
.dropdown-content span,
.dropbtn span {
    color: black;
    padding: 2px 0 2px 10px;
    text-decoration: none;
    display: block;
}

.lang-block {
    display: flex;
    align-items: center;
    padding-left: 5px;
    cursor: pointer;
}

.lang-block span {
    text-transform: uppercase;
}

.lang-block img{
    width: 16px;
    height: 16px;
}

/* Change color of dropdown links on hover */
.dropdown-content .lang-block:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
}