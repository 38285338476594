.langs-block {
    cursor: pointer;
}

.langs-block img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.languages {
    display: flex;
    align-items: center;
    min-height: 550px;
    height: 100vh;
    background: url(https://arcticairpro.shop/img/_src/background42.jpg) repeat center;
}