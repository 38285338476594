body {
    background: url(https://arcticairpro.shop/img/_src/background42.jpg) repeat center;
}

.flex {
    display: flex;
    justify-content: space-around
}

.flex > div {
    max-width: 100px;
    cursor: pointer;
}

.flex > div img {
    display: block;
    max-width: 100%;
    height: auto;
}