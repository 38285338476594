body {
    background: url(https://arcticairpro.shop/img/_src/background42.jpg) repeat center;
}

.flex {
    display: flex;
    justify-content: space-around
}

.flex > div {
    max-width: 100px;
    cursor: pointer;
}

.flex > div img {
    display: block;
    max-width: 100%;
    height: auto;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px)
    } 
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0)
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px)
    } 
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0)
    }
}

.search {
    position: relative;
    margin: 30px 0;
}

.search-result {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 220px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    list-style: none;
    background: lightgrey;
    border-radius: .25rem;
    z-index: 99;
    -webkit-animation: fadeIn ease-out .5s;
            animation: fadeIn ease-out .5s;
}

.search-field {
    position: relative;
    display: flex;
}

.search-field .search-input {
    padding-right: 30px;
}

.search-field .search-btn {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 18px;
    background: transparent;
    border: none;
    outline: none;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}

.hidden {
    display: none;
}

.list-item  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.left-side {
    display: flex;
    align-items: center;
}

.left-side .description {
    padding: 0 15px;
}

.left-side .description p {
    font-size: 0.9rem;
}

.btn-play {
    font-size: 28px;
    background: transparent;
    border: none;
    outline: none;
    -webkit-transition: .3s ease;
    transition: .3s ease;
}

.btn-play:hover {
    color: rgba(184, 43, 43, 0.644);
}

.close-btn {
    position: absolute; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    top: calc(100% + 220px - 27px);
    height: 27px;
    font-size: 22px;
    background: rgba(0, 0, 0, .8);
    text-align: center;
    color: #fff;
    z-index: 99;
    -webkit-animation: fadeIn ease-out .5s;
            animation: fadeIn ease-out .5s;
}

.close-btn i {
    cursor: pointer;
}
.item-list {
    margin-top: 1rem;
}

.item-list .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
}

h3 {
    font-size: 1.55rem;
}

.video-name {
    margin: 0;
    padding: 0 5px;
    font-size: 0.85em;
    color: #000;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
}

.video-name:hover {
    color: #555;
}

.delete-btn {
    background: transparent;
    border: none;
    outline: none;
}
.videoplayer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    height: 450px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

@media (max-width: 992px) {
    .videoplayer {
        height: 300px;
    }
}

@media (max-width: 425px) {
    .videoplayer {
        height: 230px;
    }
}
.dropbtn {
    padding: 5px 0;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: absolute;
    display: inline-block;
    top: 30px;
    right: 0;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 80px;
}

.dropbtn{
  width: 75px;
}

.dropbtn::after {
    background: rgba(0, 0, 0, 0) url("https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-16.png") no-repeat scroll center center;
    content: "";
    height: 16px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 16px;
}

/* Links inside the dropdown */
.dropdown-content span,
.dropbtn span {
    color: black;
    padding: 2px 0 2px 10px;
    text-decoration: none;
    display: block;
}

.lang-block {
    display: flex;
    align-items: center;
    padding-left: 5px;
    cursor: pointer;
}

.lang-block span {
    text-transform: uppercase;
}

.lang-block img{
    width: 16px;
    height: 16px;
}

/* Change color of dropdown links on hover */
.dropdown-content .lang-block:hover {
    background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
}
.home > div {
    position: relative;
}
.langs-block {
    cursor: pointer;
}

.langs-block img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.languages {
    display: flex;
    align-items: center;
    min-height: 550px;
    height: 100vh;
    background: url(https://arcticairpro.shop/img/_src/background42.jpg) repeat center;
}
