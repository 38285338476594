.videoplayer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    height: 450px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

@media (max-width: 992px) {
    .videoplayer {
        height: 300px;
    }
}

@media (max-width: 425px) {
    .videoplayer {
        height: 230px;
    }
}